import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TitleBody from '../templates/TitleBody';

const Datenschutz = ({ data, location }) => {
  const { html } = data.page;
  const { title } = data.page.frontmatter;

  return (
    <Layout location={location}>
      <Seo
        title={title}
        location={location}
        meta={[{ name: 'robots', content: 'noindex, nofollow' }]}
      />
      <TitleBody
        title={title}
        body={<div dangerouslySetInnerHTML={{ __html: html }} />}
      />
    </Layout>
  );
};

export default Datenschutz;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/datenschutz/" } }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
